import { BreakpointObserver } from '@angular/cdk/layout';
import { inject, Pipe, PipeTransform } from '@angular/core';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_TIME_FORMAT_24H } from '@locumsnest/core/src/lib/types/constants';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  private breakpointObserver = inject(BreakpointObserver);
  private isSmallScreen = this.breakpointObserver.isMatched('(max-width: 540px)');
  private readonly smallScreenFormat = 'MMM D-HH:mm';

  transform(value: Date | string | number, format = DATE_TIME_FORMAT_24H): string {
    if (value)
      return Time.formatDateByFormat(value, this.isSmallScreen ? this.smallScreenFormat : format);
    return '';
  }
}
