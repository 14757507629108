import { Pipe, PipeTransform } from '@angular/core';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT, TIME_FORMAT } from '@locumsnest/core/src/lib/types/constants';

@Pipe({
  name: 'dateRange',
})
export class DateRangePipe implements PipeTransform {
  constructor() {}
  transform({ startTime, endTime }: { startTime: string; endTime: string }): string {
    return (
      Time.formatDateByFormat(startTime, `${TIME_FORMAT} - ${DATE_FORMAT}`) +
      ' / ' +
      Time.formatDateByFormat(endTime, `${TIME_FORMAT} - ${DATE_FORMAT}`)
    );
  }
}
