import { Pipe, PipeTransform } from '@angular/core';

import { Time } from '@locumsnest/core/src/lib/helpers';

@Pipe({
  name: 'timeDurationText',
})
export class TimeDurationTextPipe implements PipeTransform {
  transform(start: Date | number, end: Date | number): string {
    return Time.getDurationText(start, end);
  }
}
