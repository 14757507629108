import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(size: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB'];
    let display = '';
    for (const unit of units) {
      display = size + unit;
      if (Math.floor(size / 1024) === 0) {
        break;
      }
      size = Math.trunc(Math.floor((size / 1024) * 100) / 100);
    }
    return display;
  }
}
