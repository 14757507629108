import { ChangeDetectorRef, EmbeddedViewRef, Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
  name: 'wrapFn',
  standalone: true,
})
export class WrapFnPipe implements PipeTransform {
  private context: any;

  constructor(cdRef: ChangeDetectorRef) {
    // retrieve component instance (this is a workaround)
    this.context = (cdRef as EmbeddedViewRef<Type<any>>).context;
  }

  transform<Fn extends (...args: Parameters<Fn>) => ReturnType<Fn>>(
    fn: Fn,
    ...args: Parameters<Fn>
  ): ReturnType<Fn> {
    return fn.apply(this.context, [...args]);
  }
}
