import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

import { IAttribute } from '../../interfaces/attribute';

/**
 * DReturns attribute display from attribute dictionary
 *@
 * @export
 * @class AttributeValPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'attributeDisplay',
})
@Injectable()
export class AttributeDisplayPipe implements PipeTransform {
  constructor() {}
  transform(val: number, attributeDict: Record<string, IAttribute>): string {
    return get(attributeDict, [val, 'display']);
  }
}

/**
 * Returns attribute code from attribute dictionary
 *@
 * @export
 * @class AttributeCodePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'attributeCode',
})
@Injectable()
export class AttributeCodePipe implements PipeTransform {
  constructor() {}
  transform(val: number, attributeDict: Record<string, IAttribute>): string {
    return get(attributeDict, [val, 'code']);
  }
}
