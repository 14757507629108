import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({ name: 'date', pure: true, standalone: true })
export class DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: any, format): string | null {
    if (value == null || value === '' || value !== value) return null;
    return moment(value).format(format);
  }
}
