import { Pipe, PipeTransform } from '@angular/core';

import { Time } from '@locumsnest/core/src/lib/helpers';

@Pipe({
  name: 'textDurationFromSeconds',
})
export class TextDurationFromSecondsPipe implements PipeTransform {
  transform(durationInSeconds: number): string {
    if (durationInSeconds < 0) return '-';
    return Time.getDurationTextBySeconds(durationInSeconds);
  }
}
