import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceHeadersTags',
})
export class ReplaceHeadersTagsPipe implements PipeTransform {
  transform(text: string): string {
    return text.replace(/h1 style=".+"|h2 style=".+"|h1|h2/g, 'p');
  }
}
