import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

/**
 * Formats to hours if base date is the same as given date otherwise formats as date
 *
 * @export
 * @class CompactDate
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'compactDate',
  standalone: true,
})
@Injectable()
export class CompactDatePipe implements PipeTransform {
  transform(value: Date, baseDate: Date, dateFormat: string, hourFormat: string, ...args): string {
    const momentBaseDate = moment(+baseDate);
    const momentValue = moment(+value);

    if (+momentBaseDate.clone().startOf('day') === +momentValue.clone().startOf('day')) {
      return momentValue.format(hourFormat);
    }
    return momentValue.format(dateFormat);
  }
}
