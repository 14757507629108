import { Pipe, PipeTransform } from '@angular/core';

import { IUserEntity } from '../../interfaces/user';

@Pipe({
  name: 'actionPerformer',
})
export class ActionPerformerPipe implements PipeTransform {
  transform(value: IUserEntity, ...args: unknown[]): string {
    let name = '';
    if (value) {
      if (value.firstName) name += value.firstName + ' ';
      if (value.lastName) name += value.lastName + ' ';
      if (name !== '' && value.email) name += '- ';
      if (value.email) name += value.email;
    }

    return name;
  }
}
